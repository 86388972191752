import React, { useEffect } from 'react';
import { Row, Table } from 'reactstrap';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import useFetch, { FetchSpec, HttpMethod } from '../Common/useFetch';
import { KaEmployeeProfile } from '../UserAccess/UserContext';
import { Button } from 'react-bootstrap';
import BoldButton from '../Common/BoldButton';

interface BusinessListProps {
  user: KaEmployeeProfile;
  revision: number;
  addUserClicked: (business: BusinessDto) => void;
  importProducts: (business: BusinessDto) => void;
  importDrivers: (business: BusinessDto) => void;
  manageSites: (business: BusinessDto) => void;
  disableSites: (business: BusinessDto) => void;
  enableSites: (business: BusinessDto) => void;
  createBusiness: () => void;
}

const BusinessList = (props: BusinessListProps) => {
  const fetch = useFetch<Array<BusinessDto>>(getSpec(props.user));
  useEffect(() => {
    fetch.fetch();
  }, [props.revision]);

  if (fetch.isLoading) return <CenteredSpinner />
  if (fetch.isError) return <h3>Something went wrong loading the list of businesses</h3>

  return (<>
    <Row className="justify-content-between mb-2">
      <h2 className="col-auto ka-blue">Businesses</h2>
      <BoldButton className="col-auto btn-ghost-primary" 
        disabled={!props.user.canEditBusinesses}
        onClick={props.createBusiness}>
          Create Business
      </BoldButton>
    </Row>
    <Table striped className="border">
      <thead>
        <tr>
          <th style={{width: "25%"}}>Name</th>
          <th style={{width: "25%"}}>Service Contract</th>
          <th className="text-center">Total Sites</th>
          <th className="text-center">Unused Sites</th>
          <th />
        </tr>
      </thead>
      <tbody>{
        fetch.data?.sort((a, b) => Number(a.sandbox) - Number(b.sandbox) || a.name.localeCompare(b.name)).map(business =>
          <tr key={business.id}>
            <td className="border-end">{business.name} {business.disabled && "(Disabled)"}{business.sandbox && "(Sandbox)"}</td>
            <td className="border-end">{business.serviceContractNumber}</td>
            <td className="text-center">{business.siteAllowance}</td>
            <td className="text-center">{business.siteAllowance - business.numberOfSites}</td>
            <td align="right">
              <div className="dropdown">
                <Button className='dropdown-toggle btn-ghost-secondary' variant='link' id='actionsMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                  <strong>Actions</strong>
                </Button>
                <div className='dropdown-menu' aria-labelledby='actionsMenuButton'>
                  <BoldButton className='dropdown-item btn-ghost-secondary'
                    disabled={!props.user.canEditBusinesses}
                    onClick={() => props.addUserClicked(business)}>
                    Add user
                  </BoldButton>
                  <BoldButton className='dropdown-item btn-ghost-secondary'
                    disabled={!props.user.canEditBusinesses}
                    onClick={() => props.importProducts(business)}>
                    Import products
                  </BoldButton>
                  <BoldButton className='dropdown-item btn-ghost-secondary'
                    disabled={!props.user.canEditBusinesses}
                    onClick={() => props.importDrivers(business)}>
                    Import drivers
                  </BoldButton>
                  <BoldButton className='dropdown-item btn-ghost-secondary'
                    disabled={!props.user.canEditBusinesses}
                    onClick={() => props.manageSites(business)}>
                    Manage sites
                  </BoldButton>
                  {
                    business.disabled
                      ? <BoldButton className='dropdown-item btn-ghost-secondary'
                        disabled={!props.user.canEditBusinesses}
                        onClick={() => props.enableSites(business)}>
                        Enable sites
                      </BoldButton>
                      : <BoldButton className='dropdown-item btn-ghost-secondary'
                        disabled={!props.user.canEditBusinesses}
                        onClick={() => props.disableSites(business)}>
                        Disable sites
                      </BoldButton>
                  }
                </div>
              </div>
            </td>
          </tr>
        )
      }
      </tbody>
    </Table>
  </>);
}

const getSpec = (user: KaEmployeeProfile): FetchSpec => {
  return {
    path: '/employeeApi/businesses',
    method: HttpMethod.GET,
    headers: user.fetchHeaders
  }
};

export interface BusinessDto {
  id: string;
  name: string;
  serviceContractNumber: string;
  siteAllowance: number;
  numberOfSites: number;
  disabled: boolean;
  sandbox: boolean;
}

export { BusinessList };
