import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip as ChartToolTip,
  Legend,
  TooltipPositionerFunction,
  ChartType,
  BarElement,
  ChartOptions,
  Point,
} from 'chart.js';
import { SiteDwellTimeDto } from '../../Requests/useGetDwellTime';
import { SiteDto } from '../../Requests/useGetSitesNonAdmin';
import { Bar } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

declare module 'chart.js' {
  interface TooltipPositionerMap {
    CustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}

type DwellTimeGraphProps = {
  showLoadTime: boolean;
  showCheckinTime: boolean;
  showCompanyAverage: boolean;
  companyAverageLoadTime?: number;
  dwellTimes: SiteDwellTimeDto[];
  sites: SiteDto[];
}

export const DwellTimeGraph = (props: DwellTimeGraphProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    ChartToolTip,
    annotationPlugin,
  );

  const loadColor = 'rgb(141,183,225)';
  const checkInColor = 'rgba(27, 110, 194)';

  const allSites = props.sites.concat({ id: 'AllSites', name: 'All Sites' });
  const data = props.dwellTimes.map((site) => ({
    label: allSites.find(s => s.id === site.siteId)?.name ?? '',
    loadTime: site.averageLoadTime ?? 0,
    checkinTime: site.averageCheckinTime ?? 0,
  }));

  const chartData: any = {
    labels: data.map(d => d.label),
    datasets: [
      {
        label: 'Load to Signature',
        data: data.map(d => d.loadTime),
        borderWidth: 0,
        backgroundColor: loadColor,
        categoryPercentage: .8,
        barPercentage: 1,
        hidden: !props.showLoadTime,
      },
      {
        label: 'From Check-In to Signature',
        data: data.map(d => d.checkinTime),
        borderWidth: 0,
        backgroundColor: checkInColor,
        categoryPercentage: .8,
        barPercentage: 1,
        hidden: !props.showCheckinTime,
      }
    ],
  };

  ChartToolTip.positioners.CustomPositioner = function (_, position: Point) { return position; }

  const chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    interaction: {
      mode: 'nearest' as const,
      axis: 'x' as const,
      intersect: false
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        bottom: 2,
      }
    },
    scales: {
      x: {
        grid: {
          drawTicks: false
        }
      },
      y: {
        grid: {
          drawTicks: false
        },
      }
    } as any,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          filter: function (legendItem, chartData) {
            return !legendItem.hidden;
          }
        },
        onClick: function () { return; }
      },
      tooltip: {
        position: 'CustomPositioner' as const,
        enabled: false,
        external: function (context) {
          var id = `chartjs-tooltip-${DwellTimeGraph.name}`;
          var tooltipEl = document.getElementById(id);

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.className = "bg-white border border-2 rounded-3 chart-tooltip";
            tooltipEl.id = id;
            tooltipEl.style.transition = 'all .1s ease';
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          const dataIndex = tooltipModel.dataPoints[0].dataIndex;
          const currentSite = props.dwellTimes[dataIndex];

          var tableBodyHtml = '';

          if (tooltipModel.body) {
            const noData = `<span style="color:rgba(237, 71, 52); float:right">No Data Available</span>` as string;

            if (props.showLoadTime) {
              const averageLoadTime = currentSite.averageLoadTime
                ? `<span style="float:right;">${currentSite.averageLoadTime} min</span>`
                : noData;

              tableBodyHtml += `
                <tr>
                  <td>
                    <i class="bi bi-circle-fill me-1" style="color: ${loadColor}"></i>
                    <span class="ka-blue pe-3">Load to Signature:
                       ${averageLoadTime}
                    </span>
                  </td>
                </tr>`;
            }

            if (props.showCheckinTime) {
              const averageCheckinTime = currentSite.averageCheckinTime
                ? `<span style="float:right;">${currentSite.averageCheckinTime} min</span>`
                : noData;

              tableBodyHtml += `<tr>
                <td>
                  <i class="bi bi-circle-fill me-1" style="color: ${checkInColor}"></i>
                  <span class="ka-blue pe-3">From Check-In to Signature:
                    ${averageCheckinTime}
                  </span>
                </td>
              </tr>`
            }
          }

          tooltipEl.innerHTML = `
            <table class="w-auto" >
                <thead class="ka-blue fs-5 fw-bold">
                    ${tooltipModel.title[0]}
                </thead>
                <tbody>
                  ${tableBodyHtml}
                </tbody>
              </table > `;

          const position = context.chart.canvas.getBoundingClientRect();
          const mousePositionLeft = position.left + window.scrollX;
          const mousePositionTop = position.top + window.scrollY;
          const canvasRenderedWidth = context.chart.chartArea.right - context.chart.chartArea.left;

          var tooltipLeft;
          var tooltipTop;
          if (canvasRenderedWidth - tooltipModel.caretX < 85) {
            // Tooltip to the left
            tooltipLeft = mousePositionLeft + tooltipModel.caretX - tooltipEl.clientWidth - 10 + 'px';
            tooltipTop = mousePositionTop + tooltipModel.caretY - (tooltipEl.clientHeight / 2) + 'px';
          } else {
            // Tooltip up
            tooltipLeft = mousePositionLeft + tooltipModel.caretX - (tooltipEl.clientWidth / 2) + 'px';
            tooltipTop = mousePositionTop + tooltipModel.caretY - tooltipEl.clientHeight - 10 + 'px';
          }

          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = tooltipLeft;
          tooltipEl.style.top = tooltipTop;
          tooltipEl.style.padding = '10px';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.zIndex = "9999";
        },
      },
      annotation: {
        annotations: {
          dottedLine: {
            display: props.showCompanyAverage && !!props.companyAverageLoadTime,
            type: 'line',
            yMin: props.companyAverageLoadTime,
            yMax: props.companyAverageLoadTime,
            borderColor: 'rgba(0, 0, 0, 0.5)',
            borderWidth: 2,
            borderDash: [5, 5],
          },
        },
      },
    },
  };

  return <Bar options={chartOptions} data={chartData} plugins={[ChartToolTip]} />
}
