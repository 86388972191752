import { UniqueIdentifier } from "@dnd-kit/core";
import { QueryOptions } from "../components/Common/useQuery";
import useUserQuery from "../components/Common/useUserQuery";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";
import { BulkProductAllocationSettings, ProductLoadedSettings, DwellTimeSettings, TransportsLoadedWidgetSettings } from "../components/Dashboard/DashboardContext";

export enum WidgetType {
  AggregateProductLoaded = "AggregateProductLoaded",
  SiteConnectionStatus = "SiteConnectionStatus",
  TransportsLoaded = "TransportsLoaded",
  IntegrationOrderExportStatus = "IntegrationOrderExportStatus",
  BulkProductAllocation = "BulkProductAllocation",
  DwellTime = "DwellTime",
}

export type WidgetDto =
  CommonWidgetDto & { type: WidgetType.SiteConnectionStatus } |
  CommonWidgetDto & { type: WidgetType.IntegrationOrderExportStatus } |
  CommonWidgetDto & ProductLoadedSettings & { type: WidgetType.AggregateProductLoaded } |
  CommonWidgetDto & TransportsLoadedWidgetSettings & { type: WidgetType.TransportsLoaded } |
  CommonWidgetDto & BulkProductAllocationSettings & { type: WidgetType.BulkProductAllocation } |
  CommonWidgetDto & DwellTimeSettings & { type: WidgetType.DwellTime };

interface CommonWidgetDto {
  id: UniqueIdentifier;
  name: string;
}

export type DashboardLayoutResponse = {
  widgets: WidgetDto[]
}

const useGetDashboardLayout = (options?: QueryOptions<DashboardLayoutResponse>) => {
  const business = useSelectedBusiness();
  return useUserQuery<DashboardLayoutResponse>({ path: `/api/businesses/${business.id}/dashboardLayout` }, options);
}

export default useGetDashboardLayout;
