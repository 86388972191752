import React, { useState } from "react";
import useInterval from "../../Common/useInterval";
import useGetDwellTime from "../../Requests/useGetDwellTime";
import { DwellTimeSettings, WidgetProps } from "./../DashboardContext";
import useGetSitesNonAdmin from "../../Requests/useGetSitesNonAdmin";
import { Widget } from "../WidgetV2";
import { DwellTimeWidgetSettings } from "./DwellTimeWidgetSettings";
import { DwellTimeDisplay } from "./DwellTimeDisplay";

type DwellTimeWidgetProps = WidgetProps & DwellTimeSettings;

export const DwellTime = (props: DwellTimeWidgetProps) => {
  const getSites = useGetSitesNonAdmin();
  const [settings, setSettings] = useState<DwellTimeSettings>({ ...props })

  const getDwellTime = useGetDwellTime({ dateRange: settings.dateRange! });

  useInterval(() => getDwellTime.query(), 30_000, false);

  return <Widget
    widgetId={props.id}
    title={settings.name}
    path='/dwellTime'
    onLoadQueries={{ sites: getSites }}
    dataQuery={getDwellTime}
    settings={({ sites }, show, onClose) =>
      <DwellTimeWidgetSettings
        id={props.id}
        show={show}
        onClose={onClose}
        settings={settings}
        settingsUpdate={setSettings}
        sites={sites} />
    }
    render={(dwellTime, _) =>
      <DwellTimeDisplay
        data={dwellTime}
        settings={settings} />
    }
  />
};
