
export const download = async (url: string, fileName: string, headers: Headers) => {
  const response = await window.fetch(url, { headers });
  const blob = await response.blob();

  const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = fileName;
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(downloadUrl);
}
