import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { UserProvider } from './components/UserAccess/UserProvider';
import { IPublicClientApplication } from '@azure/msal-browser';
import './custom.css'
import { MsalProvider } from '@azure/msal-react';
import { withMonitoring, MonitoringContextProvider } from './components/Monitoring/Monitoring';
import { BusinessPage } from './components/KaEmployee/BusinessPage';
import { CustomerPage } from './components/Customers/CustomerPage';
import { UserPage } from './components/Users/UserPage';
import { SitePage } from './components/Sites/SitePage';
import { UserTemplate } from './components/UserAccess/UserTemplate';
import { KaEmployeeTemplate } from './components/UserAccess/KaEmployeeTemplate';
import { ProductPage } from './components/Products/ProductPage';
import { RecipePage } from './components/Recipes/RecipePage';
import { OrderPage } from './components/Orders/OrderPage';
import { SiteRegistration } from './components/Sites/SiteRegistrationPage';
import { ExternalIntegrationsPage } from './components/ExternalIntegrations/ExternalIntegrationsPage';
import { OrderSettingsPage } from './components/Businesses/OrderSettingsPage';
import { ProductLoadedPage } from './components/Dashboard/ProductLoadedPage';
import { KahlerLogin } from './components/KahlerLogin';
import { DriverPage } from './components/Drivers/DriverPage';
import BusinessTemplate from './components/UserAccess/BusinessTemplate';
import { BranchPage } from './components/Branches/BranchPage';
import { SitesStatusPage } from './components/Dashboard/SitesStatusPage';
import { SiteNotificationPage } from './components/KaEmployee/SiteNotificationPage';
import { TransportPage } from './components/Transports/TransportPage';
import { EmployeeSitesStatusPage } from './components/KaEmployee/EmployeeSitesStatusPage';
import { EmployeeOrderPage } from './components/KaEmployee/EmployeeOrderPage';
import { CarrierPage } from './components/Carriers/CarrierPage';
import { IntegrationOrderExportStatusPage } from './components/Dashboard/IntegrationOrderExportStatusPage';
import { ApplicatorPage } from './components/Applicators/ApplicatorPage';
import { BulkProductAllocationPage } from './components/Dashboard/BulkProductAllocationPage';
import { DwellTimePage } from './components/Dashboard/DwellTime/DwellTimePage';

const UserLayout = () =>
  <UserTemplate>
    <BusinessTemplate>
      <Outlet />
    </BusinessTemplate>
  </UserTemplate>

const KaEmployeeLayout = () =>
  <KaEmployeeTemplate><Outlet /></KaEmployeeTemplate>

const App = ({ pca }: { pca: IPublicClientApplication }) => {
  return (
    <MsalProvider instance={pca}>
      <MonitoringContextProvider>
        <UserProvider>
          <Layout>
            <Routes>
              <Route path='/kahlerLogin' element={<KahlerLogin />} />
              <Route path='/siteRegistration' element={<SiteRegistration />} />
              <Route element={<UserLayout />}>
                <Route path='/' element={<Home />} />
                <Route path='/users' element={<UserPage />} />
                <Route path='/customers' element={<CustomerPage />} />
                <Route path='/branches' element={<BranchPage />} />
                <Route path='/drivers' element={<DriverPage />} />
                <Route path='/carriers' element={<CarrierPage />} />
                <Route path='/applicators' element={<ApplicatorPage />} />
                <Route path='/sites' element={<SitePage />} />
                <Route path='/ordersettings' element={<OrderSettingsPage />} />
                <Route path='/products' element={<ProductPage />} />
                <Route path='/recipes' element={<RecipePage />} />
                <Route path='/orders/:completed?/:orderNumber?' element={<OrderPage />} />
                <Route path='/transports' element={<TransportPage />} />
                <Route path='/extIntegrations' element={<ExternalIntegrationsPage />} />
                <Route path='/productLoaded' element={<ProductLoadedPage />} />
                <Route path='/sitesStatus' element={<SitesStatusPage />} />
                <Route path='/integrationOrderExportStatus' element={<IntegrationOrderExportStatusPage />} />
                <Route path='/bulkProductAllocation' element={<BulkProductAllocationPage />} />
                <Route path='/dwellTime' element={<DwellTimePage />} />
              </Route>
              <Route path='/employee/' element={<KaEmployeeLayout />}>
                <Route path='business' element={<BusinessPage />} />
                <Route path='sitesStatus' element={<EmployeeSitesStatusPage />} />
                <Route path='siteNotifications' element={<SiteNotificationPage />} />
                <Route path='orders' element={<EmployeeOrderPage />} />
              </Route>
            </Routes>
          </Layout>
        </UserProvider>
      </MonitoringContextProvider>
    </MsalProvider>
  );
}

export default withMonitoring(App);
