import { HttpMethod } from "../components/Common/useFetch";
import { RequestOptions } from "../components/Common/useRequest";
import useUserRequest from "../components/Common/useUserRequest";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export interface UpdateDwellTimeWidgetSettingsDto {
  name: string;
  selectedSiteId: string | undefined;
  dateRange: string | undefined;
  field1: string | undefined;
  field2: string | undefined;
  field3: string | undefined;
  field4: string | undefined;
}

const useUpdateDwellTimeWidgetSettings = (widgetId: string, options?: RequestOptions<void>) => {
  const business = useSelectedBusiness();
  return useUserRequest<UpdateDwellTimeWidgetSettingsDto>({
    path: `/api/businesses/${business.id}/dwellTimeWidgetSettings/${widgetId}`,
    method: HttpMethod.PUT,
  }, options);
}

export default useUpdateDwellTimeWidgetSettings;