import React, { useState } from "react"
import { WidgetProps, BulkProductAllocationSettings } from "./DashboardContext";
import { isMultiValue, KaSelect } from "../Common/KaSelect";
import useGetBulkProductAllocation from "../Requests/useGetBulkProductAllocation";
import { BulkProductAllocationDisplay } from "./BulkProductAllocationDisplay";
import KaModal from "../Common/KaModal";
import { useUser } from "../UserAccess/useUser";
import useUserQuery from "../Common/useUserQuery";
import ProductDto from "../Common/dtos/ProductDto";
import useUpdateBulkProductAllocationWidgetSettings from "../../requests/useUpdateBulkProductAllocationWidgetSettings";
import KaAlert from "../Common/KaAlert";
import useDisplayMessage from "../Common/useDisplayMessage";
import useGetSitesNonAdmin, { SiteDto } from "../Requests/useGetSitesNonAdmin";
import { Widget } from "./WidgetV2";

export const units = ["Pound", "Gallon", "Ton"];

type SettingsProps = {
  widgetId: string;
  settings: BulkProductAllocationSettings;
  settingsUpdated: (settings: BulkProductAllocationSettings) => void;
  sites: SiteDto[];
  products: ProductDto[];
  show: boolean;
  onClose: () => void;
}

const Settings = (props: SettingsProps) => {
  const displayMessage = useDisplayMessage();
  const [settings, setSettings] = useState<BulkProductAllocationSettings>({
    name: props.settings.name,
    selectedUnit: props.settings.selectedUnit,
    selectedSiteId: props.settings.selectedSiteId ?? 'AllSites',
    selectedProductIds: props.settings.selectedProductIds ?? ['', '', '', '', '']
  });

  const updateSettings = useUpdateBulkProductAllocationWidgetSettings(props.widgetId, {
    onSuccess: () => {
      props.settingsUpdated(settings);
      props.onClose();
    },
    onError: (err) => displayMessage.fail(err.message)
  });

  const update = (newSettings: Partial<BulkProductAllocationSettings>) =>
    setSettings({ ...settings, ...newSettings });

  const unitOption = (unit: string) => ({ value: unit, label: unit + "s" });

  const sortedSites = props.sites.sort((a, b) => a.name.localeCompare(b.name));
  const sortedSiteOptions = sortedSites?.map(s => ({ value: s.id, label: s.name })) ?? [];
  const AggregateSite = { value: 'AllSites', label: "All Sites" };
  const siteOptions = [AggregateSite].concat(sortedSiteOptions);

  const sortedProducts = props.products.sort((a, b) => a.name.localeCompare(b.name));
  const productOptions = sortedProducts?.map(p => ({ value: p.id, label: p.name })) ?? [];

  const saveSettings = () =>
    updateSettings.request({
      name: settings.name,
      selectedProductIds: settings.selectedProductIds!.filter((product) => product !== ''),
      selectedSiteId: settings.selectedSiteId === 'AllSites' ? undefined : settings.selectedSiteId,
      selectedUnit: settings.selectedUnit
    });

  const updateProducts = (index: number, id: string) => {
    const products = [...settings.selectedProductIds!];
    products[index] = id;
    update({ selectedProductIds: products });
  }

  return (<KaModal
    onHide={props.onClose}
    show={props.show}
    title={'Bulk Product Allocation Settings'}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <div className="row mb-3">
        <h5 className="w-100 d-flex justify-content-center">
          The bulk product allocation widget displays allocated quantities for open orders versus on-hand inventory at a site.
        </h5>
      </div>
      <h5>Widget Name:</h5>
      <input
        type="text"
        className="form-control"
        placeholder="Enter Widget Name"
        value={settings.name}
        onChange={(e) => update({ name: e.target.value })} />
      <div className="row mt-3">
        <div className="col">
          <h5>Site:</h5>
          <KaSelect
            placeholder="Select Site"
            options={siteOptions}
            onChange={(valueObject) => {
              if (!isMultiValue(valueObject)) { update({ selectedSiteId: valueObject?.value! }) }
            }}
            defaultValue={siteOptions.find((option) => option.value === settings.selectedSiteId)}
          />
        </div>
        <div className="col">
          <h5>Unit:</h5>
          <KaSelect
            placeholder="Select Unit"
            options={units.map(u => unitOption(u))}
            onChange={(valueObject) => {
              if (!isMultiValue(valueObject)) { update({ selectedUnit: valueObject?.value! }) }
            }}
            defaultValue={unitOption(settings.selectedUnit!)} />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <div className="w-75">
          <h5>Select up to 5 Products:</h5>
          {Array.from({ length: 5 }, (_, i) => (
            <KaSelect
              key={i}
              placeholder="Select Product"
              isClearable={true}
              options={productOptions}
              defaultValue={productOptions.find(o => o.value === settings.selectedProductIds![i])}
              onChange={(valueObject) => {
                if (!isMultiValue(valueObject)) { updateProducts(i, valueObject?.value ?? ''); }
              }}
              className={i === 4 ? "" : "pb-3"} />
          ))}
        </div>
      </div>
    </>)}
    footer={(
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => saveSettings()}>
        Save
      </button>)}
  />)
}

type BulkProductAllocationProps = WidgetProps & BulkProductAllocationSettings;

export const BulkProductAllocation = (props: BulkProductAllocationProps) => {
  const user = useUser();
  const business = user.selectedBusiness;
  const [settings, setSettings] = useState<BulkProductAllocationSettings>({
    name: props.name,
    selectedUnit: props.selectedUnit ?? units[0],
    selectedSiteId: props.selectedSiteId ?? 'AllSites',
    selectedProductIds: props.selectedProductIds ?? []
  });

  const getSites = useGetSitesNonAdmin();
  const getProducts = useUserQuery<ProductDto[]>({ path: `/api/businesses/${business?.id}/products` });

  const getBulkProductAllocation = useGetBulkProductAllocation({
    unit: settings.selectedUnit!,
    siteId: settings.selectedSiteId,
    products: settings.selectedProductIds,
  });

  const productCount = settings.selectedProductIds!.length >= 5
    ? 5
    : settings.selectedProductIds!.length;

  return <Widget
    widgetId={props.id}
    title={props.name}
    path='/bulkProductAllocation'
    onLoadQueries={{ sites: getSites, products: getProducts }}
    dataQuery={getBulkProductAllocation}
    settings={(onLoadData, show, onClose) =>
      <Settings
        widgetId={props.id.toString()}
        sites={onLoadData.sites}
        products={onLoadData.products}
        settings={settings}
        settingsUpdated={setSettings}
        show={show}
        onClose={onClose} />
    }
    render={(data) =>
      data.bulkProductAllocations.length === 0
        ? <div className="d-flex justify-content-center">
          <h2 className="h-100 w-100 d-flex justify-content-center align-items-center pb-5 text-wrap" style={{ color: 'darkslategrey' }}>
            Please select products to view inventory levels
          </h2>
        </div>
        : <div className="h-100 w-100">
          <BulkProductAllocationDisplay data={data.bulkProductAllocations.slice(0, productCount)} unit={settings.selectedUnit!.concat('s')} />
        </div>
    }
  />
}
