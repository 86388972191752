import React, { useContext, useState } from 'react';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import { NavLink } from 'react-router-dom';
import { SortableItem } from '../Common/Sortable/SortableItem';
import { UniqueIdentifier } from '@dnd-kit/core';
import { DashboardContext } from './DashboardContext';
import MultiQueryView, { QueryObject, QueriesResult } from '../Common/MultiQueryView';
import { Query } from '../Common/useQuery';

type WidgetProps<OneTimeQueries extends QueryObject, TData> = {
  widgetId: UniqueIdentifier;
  title: string;
  path?: string;
  onLoadQueries: OneTimeQueries;
  dataQuery: Query<TData>;
  render: (data: TData, onLoadData: QueriesResult<OneTimeQueries>) => JSX.Element;
  settings?: (onLoadData: QueriesResult<OneTimeQueries>, show: boolean, onClose: () => void) => JSX.Element;
}

export const WidgetComponent = <TOneTime extends QueryObject, TData>(props: WidgetProps<TOneTime, TData>) => {
  const dashboard = useContext(DashboardContext);
  const widget = dashboard.widgets.find((w) => w.id === props.widgetId);

  const [showSettings, setShowSettings] = useState<boolean>(false);
  const toggleModal = () => {
    setShowSettings(!showSettings);
  }

  return <>
    {props.settings &&
      <MultiQueryView
        queries={props.onLoadQueries}
        loading={<></>}
        renderError={() => <></>}
        renderData={data => props.settings!(data, showSettings, toggleModal)} />
    }
    <SortableItem key={props.widgetId.toString()} id={props.widgetId.toString()} handle={false}>
      <div className="widget card h-100 border position-relative" ref={widget?.componentRef}>
        {props.settings &&
          <MultiQueryView
            queries={props.onLoadQueries}
            loading={<></>}
            renderData={_ =>
              <button className="btn m-0 position-absolute top-0 end-0 translate-end" onClick={() => toggleModal()} data-testid="settings">
                <i className="bi bi-gear ka-blue" style={{ fontSize: "1.25em" }} />
              </button>} />
        }
        <div className="card-header card-header-content-sm-between mb-0 pb-0 pt-3">
          <h4 className="card-header-title text-center ka-blue">{props.title}</h4>
        </div>
        <hr className="hr mt-2 mb-2 mx-5" />
        <NavLinkWrapper path={props.path}>
          <div className="card-body py-0 px-2 mt-0 h-100">
            <MultiQueryView
              queries={{ ...props.onLoadQueries, data: props.dataQuery }}
              loading={
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <CenteredSpinner />
                </div>}
              renderData={t => props.render(t.data, t)}
              renderError={error => <div className="col h-100 d-flex align-items-center" style={{ color: 'rgba(237, 71, 52)' }}>
                <div className="w-100 mx-4">
                  <div className="row text-center">
                    <i className="bi bi-x-circle-fill fs-1"></i>
                  </div>
                  <div className="row text-center fs-5">
                    {error.message}
                  </div>
                </div>
              </div>} />
          </div>
        </NavLinkWrapper>
      </div>
    </SortableItem>
  </>
}

const NavLinkWrapper = ({ path, children }: { path?: string, children: React.ReactNode }) => {
  return (path !== undefined ?
    <NavLink to={path} className="advanced-view text-decoration-none text-reset">
      <div className="btn m-0 position-absolute bottom-0 end-0 translate-end">
        <i className="bi bi-box-arrow-in-down-right ka-blue" style={{ fontSize: "1.5em" }} />
      </div>
      {children}
    </NavLink>
    : <>{children}</>)
};

export const Widget = React.memo(WidgetComponent) as typeof WidgetComponent;
