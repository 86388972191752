import React, { memo, useContext, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Item } from '../Common/Sortable/Item';
import { DashboardContext, Widget } from './DashboardContext';
import { WidgetType } from '../../requests/useGetDashboardLayout';
import { ProductLoaded } from './ProductLoadedWidget';
import { TransportsLoaded } from './TransportsLoadedWidget';
import { SitesStatus } from './SitesStatusWidget';
import { IntegrationOrderExportStatus } from './IntegrationOrderExportStatusWidget';
import { BulkProductAllocation } from './BulkProductAllocationWidget';
import { AddRemoveWidget, deleteWidgetId } from './AddRemoveWidget';
import { DwellTime } from './DwellTime/DwellTimeWidget';

const RenderWidget = memo(function (widget: Widget) {
  switch (widget.type) {
    case WidgetType.AggregateProductLoaded:
      return <ProductLoaded {...widget} />;

    case WidgetType.TransportsLoaded:
      return <TransportsLoaded {...widget} />;

    case WidgetType.SiteConnectionStatus:
      return <SitesStatus {...widget} />;

    case WidgetType.IntegrationOrderExportStatus:
      return <IntegrationOrderExportStatus {...widget} />;

    case WidgetType.BulkProductAllocation:
      return <BulkProductAllocation {...widget} />;

    case WidgetType.DwellTime:
      return <DwellTime {...widget} />;
  }
});

export const Dashboard = () => {
  const { widgets, swapWidgets, removeWidget } = useContext(DashboardContext);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: DragStartEvent) => setActiveId(event.active.id);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over?.id === deleteWidgetId) {
      removeWidget(active.id);
    } else if (over && active.id !== over.id) {
      swapWidgets(active.id, over.id);
    }

    setActiveId(null);
  }

  return <DndContext
    sensors={sensors}
    collisionDetection={closestCenter}
    onDragStart={handleDragStart}
    onDragEnd={handleDragEnd}>
    <SortableContext
      items={widgets.map(w => w.id)}>
      <div className="widget-grid mt-n5 mb-n5 mx-n3">
        {widgets.map((widget) => <RenderWidget key={widget.id} {...widget} />)}
        <AddRemoveWidget isDragging={!!activeId} />
      </div>
    </SortableContext>
    <DragOverlay>
      {activeId ? <Item id={activeId.toString()} isDragging>
        <div dangerouslySetInnerHTML={{ __html: widgets.find(w => w.id === activeId)?.componentRef.current?.outerHTML ?? "" }} />
      </Item> : null}
    </DragOverlay>
  </DndContext>
}
