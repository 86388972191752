import { QueryOptions } from "../Common/useQuery";
import useUserQuery from "../Common/useUserQuery";
import { useSelectedBusiness } from "../UserAccess/useSelectedBusiness";

export type SiteDwellTimeDto = {
  siteId: string;
  averageLoadTime?: number;
  averageCheckinTime?: number;
}

export type DwellTimeDto = {
  siteTimes: SiteDwellTimeDto[],
  companyAverageLoadTime: number,
  slowestSiteLoadTime: number,
  fastestSiteLoadTime: number,
  companyAverageFromCheckinTime: number,
  slowestSiteFromCheckinTime: number,
  fastestSiteFromCheckinTime: number,
}

export type DwellTimeParams = {
  dateRange: string,
  download?: boolean,
  startDate?: string,
  endDate?: string,
}

export const formatDwellTimeQuery = (businessId: string, params: DwellTimeParams) => {
  const urlParams = new URLSearchParams([
    ['dateRange', params.dateRange],
    ['timeZoneOffset', new Date().getTimezoneOffset().toString()],
  ]);

  if (params.startDate) urlParams.append('startDate', params.startDate);
  if (params.endDate) urlParams.append('endDate', params.endDate);

  var path = `/api/businesses/${businessId}/dwellTime`;

  if (params.download ?? false) {
    path = path.concat("/download");
  }

  return `${path}?${urlParams.toString()}`;
}

const useGetDwellTime = (params: DwellTimeParams, options?: QueryOptions<DwellTimeDto>) => {
  const business = useSelectedBusiness();

  return useUserQuery<DwellTimeDto>(
    { path: formatDwellTimeQuery(business.id, params) },
    options);
}

export default useGetDwellTime;