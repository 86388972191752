import { useState } from "react";
import useDisplayMessage from "../../Common/useDisplayMessage";
import useUpdateDwellTimeWidgetSettings from "../../../requests/useUpdateDwellTimeWidgetSettings";
import { DwellTimeSettings } from "../DashboardContext";
import KaModal from "../../Common/KaModal";
import { SiteDto } from "../../Requests/useGetSitesNonAdmin";
import React from "react";
import KaAlert from "../../Common/KaAlert";
import { isMultiValue, KaSelect } from "../../Common/KaSelect";
import { UniqueIdentifier } from "@dnd-kit/core";

export enum DwellTimeConfig {
  AverageLoadTime = 'AverageLoadTime',
  CompanyAverageLoadTime = 'CompanyAverageLoadTime',
  SlowestSiteLoadTime = 'SlowestSiteLoadTime',
  FastestSiteLoadTime = 'FastestSiteLoadTime',
  AverageFromCheckinTime = 'AverageFromCheckinTime',
  CompanyAverageFromCheckinTime = 'CompanyAverageFromCheckinTime',
  SlowestSiteFromCheckinTime = 'SlowestSiteFromCheckinTime',
  FastestSiteFromCheckinTime = 'FastestSiteFromCheckinTime',
};

const dwellTimeFieldOptions = [
  { value: DwellTimeConfig.AverageLoadTime, label: 'Site average time from load to signature' },
  { value: DwellTimeConfig.CompanyAverageLoadTime, label: 'Company average time from load to signature' },
  { value: DwellTimeConfig.SlowestSiteLoadTime, label: 'Slowest site average time from load to signature' },
  { value: DwellTimeConfig.FastestSiteLoadTime, label: 'Fastest site average time from load to signature' },
  { value: DwellTimeConfig.AverageFromCheckinTime, label: 'Site average time from check-in to signature' },
  { value: DwellTimeConfig.CompanyAverageFromCheckinTime, label: 'Company average time from check-in to signature' },
  { value: DwellTimeConfig.SlowestSiteFromCheckinTime, label: 'Slowest site average time from check-in to signature' },
  { value: DwellTimeConfig.FastestSiteFromCheckinTime, label: 'Fastest site average time from check-in to signature' },
];

const dateRangeOptions = [
  { value: 'Week', label: 'Last week' },
  { value: 'Month', label: 'Last month' },
  { value: 'Quarter', label: 'Last quarter' },
  { value: 'YearToDate', label: 'Year to date' },
];

const dwellTimeFields = (settings: DwellTimeSettings) => {
  return [settings.field1, settings.field2, settings.field3, settings.field4]
    .filter((item): item is string => typeof item === 'string');
}

type SettingsProps = {
  id: UniqueIdentifier,
  show: boolean,
  settings: Required<DwellTimeSettings>,
  settingsUpdate: (settings: DwellTimeSettings) => void,
  sites: SiteDto[],
  onClose: () => void,
}

export const DwellTimeWidgetSettings = (props: SettingsProps) => {
  const dwellTimeSettings = 'Dwell Time Settings';
  const displayMessage = useDisplayMessage();
  const [modifiedName, setModifiedName] = useState(props.settings.name);
  const [selectedSite, setSelectedSite] = useState<string>(props.settings.selectedSiteId ?? 'AllSites');
  const [selectedDateRange, setSelectedDateRange] = useState<string>(props.settings.dateRange);
  const [selectedDwellTimeFields, setSelectedDwellTimeFields] = useState<string[]>(dwellTimeFields(props.settings));

  const updateSettings = useUpdateDwellTimeWidgetSettings(props.id.toString(), {
    onSuccess: () => {
      props.settingsUpdate(getSettingsFromForm());
      displayMessage.clear();
      props.onClose();
    },
    onError: (err) => displayMessage.fail(err.message)
  });

  const sortedSites = props.sites.sort((a, b) => a.name.localeCompare(b.name));
  const sortedSiteOptions = sortedSites.map(s => ({ value: s.id, label: s.name }));
  const AggregateSite = { value: 'AllSites', label: "All Sites" };
  const siteOptions = [AggregateSite].concat(sortedSiteOptions);

  const selectedDwellTimeFieldOptions = dwellTimeFieldOptions.filter(dtfo => selectedDwellTimeFields.some(dtf => dtf === dtfo.value));

  const getSettingsFromForm = () => ({
    name: modifiedName,
    selectedSiteId: selectedSite === 'AllSites' ? undefined : selectedSite,
    dateRange: selectedDateRange,
    field1: selectedDwellTimeFields?.[0],
    field2: selectedDwellTimeFields?.[1],
    field3: selectedDwellTimeFields?.[2],
    field4: selectedDwellTimeFields?.[3],
  });

  const saveSettings = () => updateSettings.request(getSettingsFromForm());

  return <KaModal
    onHide={props.onClose}
    show={props.show}
    title={dwellTimeSettings}
    body={<div className="mt-3">
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <h5>Widget Name:</h5>
      <input
        type="text"
        className="form-control"
        placeholder="Enter Widget Name"
        value={modifiedName}
        onChange={(e) => setModifiedName(e.target.value)} />
      <div className="row mt-3">
        <div className="col">
          <h5>Site:</h5>
          <KaSelect
            options={siteOptions}
            defaultValue={siteOptions.find((option) => option.value === selectedSite!)}
            onChange={(valueObject) => {
              if (!isMultiValue(valueObject)) { setSelectedSite(valueObject?.value!); }
            }}
          />
        </div>
        <div className="col">
          <h5>Date Range:</h5>
          <KaSelect
            options={dateRangeOptions}
            defaultValue={dateRangeOptions.find((option) => option.value === selectedDateRange!)}
            onChange={(valueObject) => {
              if (!isMultiValue(valueObject)) { setSelectedDateRange(valueObject?.value!); }
            }}
          />
        </div>
      </div>
      <div className="row mt-3">
        <h5>Dwell Time Metrics:</h5>
        <KaSelect
          placeholder="Select Metrics..."
          isClearable={true}
          closeMenuOnSelect={false}
          isMulti={true}
          options={dwellTimeFieldOptions}
          defaultValue={selectedDwellTimeFieldOptions}
          isOptionDisabled={() => {
            if (!!selectedDwellTimeFields) return (selectedDwellTimeFields!.length >= 4)
            else return true;
          }}
          onChange={(valueObject) => {
            if (isMultiValue(valueObject)) { setSelectedDwellTimeFields(valueObject?.map(p => p.value)) }
          }} />
      </div>
    </div>}
    footer={(
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => saveSettings()}>
        Save
      </button>)}
  />
}
