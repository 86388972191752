import React from 'react';
import { Query } from './useQuery';
import { Error } from './useFetch';
import { CenteredSpinner } from './CenteredSpinner';
import { Alert } from 'reactstrap';

export type QueryObject = { [key: string]: Query<any> };
export type QueriesResult<T extends QueryObject> = { [K in keyof T]: NonNullable<T[K]['data']> };

interface QueryViewProps<Queries extends QueryObject> {
  queries: Queries;
  loading?: JSX.Element;
  renderError?: (err: Error) => JSX.Element;
  renderData: (data: QueriesResult<Queries>) => JSX.Element;
}

const DefaultError = (e: Error) => <Alert color="danger">{e.message}</Alert>;
const DefaultLoader = <CenteredSpinner />;

export default function MultiQueryView<Queries extends QueryObject>(
  props: QueryViewProps<Queries>
) {
  const { queries, renderData, loading, renderError } = props;
  const error = renderError ?? DefaultError;

  const isLoading = Object.values(queries).some((query) => query.isLoading);
  const hasError = Object.values(queries).find((query) => query.isError);

  if (isLoading) return loading ?? DefaultLoader;
  if (hasError) return error(hasError.error as Error);

  const data = Object.fromEntries(
    Object.entries(queries).map(([key, query]) => [key, query.data as NonNullable<typeof query.data>])
  ) as QueriesResult<Queries>;

  return renderData(data);
}
