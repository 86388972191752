import React from 'react';
import { DwellTimeDto } from "../../Requests/useGetDwellTime";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CountUp from 'react-countup';
import { DwellTimeSettings } from '../DashboardContext';
import { DwellTimeConfig } from './DwellTimeWidgetSettings';

interface DwellTimeDisplayValues {
  dwellTimeName: string;
  subheader: string;
}

const fromLoadToSignature = 'From Load to Signature';
const fromCheckInToSignature = 'From Check-in to Signature';

const dwellTimeDisplayText = new Map<string, DwellTimeDisplayValues>([
  [DwellTimeConfig.AverageLoadTime, {
    dwellTimeName: 'Site Average ',
    subheader: fromLoadToSignature
  }],
  [DwellTimeConfig.CompanyAverageLoadTime, {
    dwellTimeName: 'Company Average ',
    subheader: fromLoadToSignature
  }],
  [DwellTimeConfig.SlowestSiteLoadTime, {
    dwellTimeName: 'Slowest Site Average ',
    subheader: fromLoadToSignature
  }],
  [DwellTimeConfig.FastestSiteLoadTime, {
    dwellTimeName: 'Fastest Site Average ',
    subheader: fromLoadToSignature
  }],
  [DwellTimeConfig.AverageFromCheckinTime, {
    dwellTimeName: 'Site Average ',
    subheader: fromCheckInToSignature
  }],
  [DwellTimeConfig.CompanyAverageFromCheckinTime, {
    dwellTimeName: 'Company Average ',
    subheader: fromCheckInToSignature
  }],
  [DwellTimeConfig.SlowestSiteFromCheckinTime, {
    dwellTimeName: 'Slowest Site Average ',
    subheader: fromCheckInToSignature
  }],
  [DwellTimeConfig.FastestSiteFromCheckinTime, {
    dwellTimeName: 'Fastest Site Average ',
    subheader: fromCheckInToSignature
  }],
]);

const Row = ({ first, second }: { first: React.ReactNode, second?: React.ReactNode }) =>
  <div className="row my-2 px-3">
    <div className="col ps-0">
      {first}
    </div>
    {second && <div className="col ps-0">
      {second}
    </div>}
  </div>

const getValueByConfig = (config: DwellTimeConfig, data: DwellTimeDto, siteId?: string) => {
  switch (config) {
    case DwellTimeConfig.AverageLoadTime:
      return siteId
        ? data.siteTimes.find(s => s.siteId === siteId)?.averageLoadTime
        : data.companyAverageLoadTime;

    case DwellTimeConfig.AverageFromCheckinTime:
      return siteId
        ? data.siteTimes.find(s => s.siteId === siteId)?.averageCheckinTime
        : data.companyAverageFromCheckinTime;

    case DwellTimeConfig.CompanyAverageLoadTime:
      return data.companyAverageFromCheckinTime;

    case DwellTimeConfig.SlowestSiteLoadTime:
      return data.slowestSiteLoadTime;

    case DwellTimeConfig.FastestSiteLoadTime:
      return data.fastestSiteLoadTime;

    case DwellTimeConfig.CompanyAverageFromCheckinTime:
      return data.companyAverageFromCheckinTime;

    case DwellTimeConfig.SlowestSiteFromCheckinTime:
      return data.slowestSiteFromCheckinTime;

    case DwellTimeConfig.FastestSiteFromCheckinTime:
      return data.fastestSiteFromCheckinTime;
  }
}

const DwellTimeField = ({ amount, fieldName, compact }:
  {
    amount?: number,
    fieldName: string,
    compact?: boolean,
  }) => {

  const dwellTimeName = dwellTimeDisplayText.get(fieldName)?.dwellTimeName;

  return <div className="w-100">
    <div className="row px-0 justify-content-center">
      <h4 className="ka-blue mb-0 w-auto text-center">
        {amount === undefined
          ? <OverlayTrigger
            placement={'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip>
                {"No loads with required data available in this time frame"}
              </Tooltip>
            }>
            <span className="text-muted">N/A</span>
          </OverlayTrigger>
          : <>
            {compact && <div>
              {dwellTimeName}
            </div>
            }
            <CountUp
              end={amount}
              prefix={compact ? "" : dwellTimeName}
              suffix={` Minute${(amount === 1 ? "" : "s")}`}
              duration={0.5}
              redraw={false}
              preserveValue={true} />
          </>}
      </h4>
    </div>
    <div className="row px-0 justify-content-center">
      <p className="mb-0 w-auto" style={{ fontSize: 13 }}>
        {dwellTimeDisplayText.get(fieldName)?.subheader}
      </p>
    </div>
  </div>
};

export const DwellTimeDisplay = ({ data, settings }: { data: DwellTimeDto, settings: DwellTimeSettings }) => {
  const fields = [settings.field1, settings.field2, settings.field3, settings.field4].filter(f => f !== undefined);
  const values = fields.map(f => getValueByConfig(f as DwellTimeConfig, data, settings.selectedSiteId));

  return <div className="align-items-center">
    {fields.length === 0 &&
      <div className="row my-2 px-3">
        <h2 className="h-100 w-100 d-flex justify-content-center align-items-center pb-5 text-wrap" style={{ color: 'darkslategrey' }}>
          Configure the widget to display dwell time metrics
        </h2>
      </div>
    }
    {fields.length === 1 &&
      <Row first={
        <DwellTimeField
          amount={values[0]}
          fieldName={fields[0]!} />} />
    }
    {fields.length === 2 && <>
      <Row first={
        <DwellTimeField
          amount={values[0]}
          fieldName={fields[0]!} />} />

      <Row first={
        <DwellTimeField
          amount={values[1]}
          fieldName={fields[1]!} />} />
    </>
    }
    {fields.length >= 3 && <>
      <Row
        first={
          <DwellTimeField
            amount={values[0]}
            fieldName={fields[0]!}
            compact={true} />}
        second={
          <DwellTimeField
            amount={values[1]}
            fieldName={fields[1]!}
            compact={true} />} />
      <Row
        first={
          <DwellTimeField
            amount={values[2]}
            fieldName={fields[2]!}
            compact={true} />}
        second={
          fields.length === 4 &&
          <DwellTimeField
            amount={values[3]}
            fieldName={fields[3]!}
            compact={true} />} />
    </>
    }
  </div>
};

