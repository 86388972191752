import React, { useState } from 'react';
import { BusinessAddUser, BusinessAddAdminUserDto } from '../Businesses/BusinessAddUser';
import { BusinessDto, BusinessList } from '../Businesses/BusinessList';
import KaAlert from '../Common/KaAlert';
import useDisplayMessage from '../Common/useDisplayMessage';
import { CreateBusiness } from '../Businesses/CreateBusiness';
import { KaEmployeeTemplate } from '../UserAccess/KaEmployeeTemplate';
import { useKaEmployee } from '../UserAccess/useKaEmployee';
import { ImportProducts } from '../Businesses/ImportProducts';
import { ImportDrivers } from '../Businesses/ImportDriver';
import { ManageSites } from '../Businesses/ManageSites';
import DisableSites from '../Businesses/DisableSites';
import useKaEmployeeRequest, { UserRequestSpec } from '../Common/useKaEmployeeRequest';
import { HttpMethod } from '../Common/useFetch';

enum States {
  None,
  AddUser,
  ImportProducts,
  ImportDrivers,
  ManageSites,
  DisableSites,
  EnableSites,
  CreateBusiness
}

type PopupState =
  {
    state: States.None;
  } |
  {
    state: States.AddUser;
    Business: BusinessDto;
  } |
  {
    state: States.ImportProducts;
    Business: BusinessDto;
  } |
  {
    state: States.ImportDrivers;
    Business: BusinessDto;
  } |
  {
    state: States.ManageSites;
    Business: BusinessDto;
  } |
  {
    state: States.DisableSites;
    Business: BusinessDto;
  } |
  {
    state: States.EnableSites;
    Business: BusinessDto;
  } |
  {
    state: States.CreateBusiness;
  };

const enableSitesSpec = (businessId?: string): UserRequestSpec => ({
  path: `/employeeApi/businesses/${businessId}/enable`,
  method: HttpMethod.POST,
});

const BusinessPage = () => {
  const kahlerUser = useKaEmployee();
  const displayMessage = useDisplayMessage();

  const [revision, setRevision] = useState(0);
  const [popupState, setPopupState] = useState<PopupState>({ state: States.None });

  const businessId = popupState.state != States.None && popupState.state != States.CreateBusiness ? popupState.Business.id : '';

  const useEnableSites = useKaEmployeeRequest<void>(enableSitesSpec(businessId), {
    onSuccess: () => {
      displayMessage.success("Sites enabled successfully");
      setRevision(revision + 1);
    },
    onError: (e) => displayMessage.fail(e.message),
  });

  const addUserToBusiness = (business: BusinessDto) => {
    displayMessage.clear();
    setPopupState({ state: States.AddUser, Business: business });
  }

  const importProducts = (business: BusinessDto) => {
    displayMessage.clear();
    setPopupState({ state: States.ImportProducts, Business: business });
  }

  const importDrivers = (business: BusinessDto) => {
    displayMessage.clear();
    setPopupState({ state: States.ImportDrivers, Business: business });
  }

  const manageSites = (business: BusinessDto) => {
    displayMessage.clear();
    setPopupState({ state: States.ManageSites, Business: business });
  }

  const disableSites = (business: BusinessDto) => {
    displayMessage.clear();
    setPopupState({ state: States.DisableSites, Business: business });
  }

  const enableSites = (business: BusinessDto) => {
    displayMessage.clear();
    setPopupState({ state: States.EnableSites, Business: business });
    useEnableSites.request();
  }

  const createBusiness = () => {
    displayMessage.clear();
    setPopupState({ state: States.CreateBusiness });
  }

  const addUserSuccess = (u: BusinessAddAdminUserDto, business: BusinessDto) => {
    displayMessage.success(`Added ${u.emailAddress} to business: ${business.name}`);
    clearState();
  }

  const importSuccess = (message: string) => {
    displayMessage.success(message);
    clearState();
  }

  const manageSitesSuccess = (message: string) => {
    displayMessage.success(message);
    clearState();
    setRevision(revision + 1);
  }

  const disableSitesSuccess = (message: string) => {
    displayMessage.success(message);
    clearState();
    setRevision(revision + 1);
  }

  const businessCreated = (message: string) => {
    displayMessage.success(message);
    clearState();
    setRevision(revision + 1);
  }

  const clearState = () => setPopupState({ state: States.None });

  return (
    <>
      <KaEmployeeTemplate>
        {popupState.state === States.CreateBusiness && <CreateBusiness 
          kaUser={kahlerUser} 
          onSuccess={businessCreated} 
          onHide={clearState}/>}
        {popupState.state === States.AddUser && <BusinessAddUser
          kaUser={kahlerUser}
          business={popupState.Business}
          onSuccess={addUserSuccess}
          onHide={clearState} />}
        {popupState.state === States.ImportProducts && <ImportProducts
          kaUser={kahlerUser}
          business={popupState.Business}
          onSuccess={importSuccess}
          onHide={clearState} />}
        {popupState.state === States.ImportDrivers && <ImportDrivers
          kaUser={kahlerUser}
          business={popupState.Business}
          onSuccess={importSuccess}
          onHide={clearState} />}
        {popupState.state === States.ManageSites && <ManageSites
          kaUser={kahlerUser}
          business={popupState.Business}
          onSuccess={manageSitesSuccess}
          onHide={clearState} />}
        {popupState.state === States.DisableSites && <DisableSites
          business={popupState.Business}
          onSuccess={disableSitesSuccess}
          onHide={clearState} />}
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <BusinessList
          user={kahlerUser}
          revision={revision}
          addUserClicked={addUserToBusiness}
          importProducts={importProducts}
          importDrivers={importDrivers}
          manageSites={manageSites}
          disableSites={disableSites}
          enableSites={enableSites}
          createBusiness={createBusiness}
        />
      </KaEmployeeTemplate>
    </>
  );
}

export { BusinessPage };
