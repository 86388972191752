import { UniqueIdentifier } from '@dnd-kit/core';
import React from 'react';
import { WidgetDto, WidgetType } from '../../requests/useGetDashboardLayout';

export interface WidgetProps {
  id: UniqueIdentifier;
  name: string;
}

export interface CommonWidget {
  id: UniqueIdentifier;
  name: string;
  componentRef: React.RefObject<HTMLDivElement>;
}

export type Widget =
  CommonWidget & { type: WidgetType.AggregateProductLoaded } & ProductLoadedSettings |
  CommonWidget & { type: WidgetType.IntegrationOrderExportStatus } |
  CommonWidget & { type: WidgetType.TransportsLoaded } & TransportsLoadedWidgetSettings |
  CommonWidget & { type: WidgetType.SiteConnectionStatus } |
  CommonWidget & { type: WidgetType.BulkProductAllocation } & BulkProductAllocationSettings |
  CommonWidget & { type: WidgetType.DwellTime } & DwellTimeSettings; 

export interface CommonSettings {
  name: string;
}

export interface ProductLoadedSettings extends CommonSettings {
  selectedProductId: string | undefined;
  selectedSiteId: string | undefined;
  selectedUnit: string | undefined;
}

export interface TransportsLoadedWidgetSettings extends CommonSettings  {
  selectedSiteId: string | undefined;
}

export interface BulkProductAllocationSettings {
  name: string;
  selectedProductIds: string[] | undefined;
  selectedSiteId: string | undefined;
  selectedUnit: string | undefined;
}

export interface DwellTimeSettings extends CommonSettings {
  selectedSiteId: string | undefined;
  dateRange: string;
  field1: string | undefined;
  field2: string | undefined;
  field3: string | undefined;
  field4: string | undefined;
}

export interface Dashboard {
  widgets: Widget[];
  swapWidgets: (dragWidgetId: UniqueIdentifier, dropWidgetId: UniqueIdentifier) => void;
  addWidget: (widget: WidgetDto) => void;
  removeWidget: (widgetId: UniqueIdentifier) => void;
}

export const DashboardContext = React.createContext<Dashboard>({
  widgets: [],
  swapWidgets: () => null,
  addWidget: () => null,
  removeWidget: () => null,
});
