import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useLocalStorage from '../../Common/useLocalStorage';
import { isMultiValue, KaSelect } from '../../Common/KaSelect';
import KaModal from '../../Common/KaModal';
import useGetDwellTime, { DwellTimeDto, DwellTimeParams, formatDwellTimeQuery } from '../../Requests/useGetDwellTime';
import useGetSitesNonAdmin, { SiteDto } from '../../Requests/useGetSitesNonAdmin';
import MultiQueryView from '../../Common/MultiQueryView';
import { DwellTimeGraph } from './DwellTimeGraph';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useSelectedBusiness } from '../../UserAccess/useSelectedBusiness';
import { useUser } from '../../UserAccess/useUser';
import { download } from '../../Common/FileDownload';

const dateRangeOptions = [
  { value: 'Week', label: 'Last week' },
  { value: 'Month', label: 'Last month' },
  { value: 'Quarter', label: 'Last quarter' },
  { value: 'YearToDate', label: 'Year to date' },
  { value: 'Custom', label: 'Custom Range' }
];

type Settings = {
  selectedSites: string[];
  showLoadTime: boolean;
  showCheckinTime: boolean;
  showCompanyAverage: boolean;
  dateRange: string;
  startDate?: string;
  endDate?: string;
}

const defaultSettings: Settings = {
  selectedSites: [],
  showLoadTime: false,
  showCheckinTime: false,
  showCompanyAverage: false,
  dateRange: 'Week'
}

const toMydString = (date: Dayjs): string => date.format('YYYY-MM-DD');

type DateRangeProps = {
  startDate: string;
  endDate: string;
  rangeUpdated: (startDate: string, endDate: string) => void;
}

const DateRange = (props: DateRangeProps) => {
  const updateStartDate = (date: Dayjs | null) => {
    if (!date) return;
    if (dayjs() < date) { date = dayjs() }

    var endDate = dayjs(props.endDate);

    if (endDate && endDate < date) {
      endDate = date;
    }

    props.rangeUpdated(toMydString(date), toMydString(endDate));
  }

  const updateEndDate = (date: Dayjs | null) => {
    if (!date) return;
    if (dayjs() < date) { date = dayjs() }

    var startDate = dayjs(props.startDate);

    if (startDate && date < startDate) {
      startDate = date;
    }

    props.rangeUpdated(toMydString(startDate), toMydString(date))
  }

  return <div className="row mt-4 mb-2">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='col-5'>
        <DatePicker
          label="Start Date"
          className="p-0"
          sx={{ zIndex: 0 }}
          value={dayjs(props.startDate)}
          onChange={(newValue) => updateStartDate(newValue)} />
      </div>
      <div className='col-5'>
        <DatePicker
          label="End Date"
          sx={{ zIndex: 0 }}
          value={dayjs(props.endDate)}
          onChange={(newValue) => updateEndDate(newValue)} />
      </div>
    </LocalizationProvider>
  </div>
}

type DwellTimeSettingsProps = {
  show: boolean;
  onClose: () => void;
  sites: SiteDto[];
  settings: Settings;
  saveSettings: (settings: Settings) => void;
}

const DwellTimeSettings = (props: DwellTimeSettingsProps) => {
  const [settings, setSettings] = useState<Settings>(props.settings);

  const updateSettings = (newSettings: Partial<Settings>) =>
    setSettings({ ...settings, ...newSettings });

  const sortedSites = props.sites.sort((a, b) => a.name.localeCompare(b.name));
  const sortedSiteOptions = sortedSites?.map(s => ({ value: s.id, label: s.name })) ?? [];
  const AggregateSite = { value: 'AllSites', label: "All Sites" };
  const siteOptions = [AggregateSite].concat(sortedSiteOptions);

  const saveSettings = () => {
    props.saveSettings(settings);
    props.onClose();
  }

  const setDateRange = (dateRange: string) => {
    var startDate: string | undefined = undefined;
    var endDate: string | undefined = undefined;

    if (dateRange === 'Custom') {
      startDate = toMydString(dayjs().subtract(1, 'month'));
      endDate = toMydString(dayjs());
    }

    updateSettings({ dateRange, startDate, endDate })
  }

  return (<KaModal
    onShow={() => setSettings(props.settings)}
    onHide={props.onClose}
    show={props.show}
    title={'Dwell Time Settings'}
    body={(<>
      <div className="row mt-3">
        <h5>Date Range:</h5>
        <KaSelect
          options={dateRangeOptions}
          value={dateRangeOptions.find((option) => option.value === settings.dateRange!)}
          onChange={(valueObject) => {
            if (!isMultiValue(valueObject)) { setDateRange(valueObject?.value!) }
          }}
        />
      </div>
      {
        settings.dateRange === 'Custom' &&
        <DateRange
          startDate={settings.startDate!}
          endDate={settings.endDate!}
          rangeUpdated={(startDate, endDate) => updateSettings({ startDate, endDate })} />
      }
      <div className="row ms-3 mt-3">
        <div className="form-check">
          <input
            type="checkbox"
            id="loadToSignature"
            className="form-check-input"
            checked={settings.showLoadTime}
            onChange={(e) => updateSettings({ showLoadTime: e.target.checked })}
          />
          <label className="form-check-label" htmlFor="loadToSignature">Time between clicking load and signature</label>
        </div>
        <div className="row">
          <div className="form-check">
            <input
              type="checkbox"
              id="checkToSignature"
              className="form-check-input"
              checked={settings.showCheckinTime}
              onChange={(e) => updateSettings({ showCheckinTime: e.target.checked })}
            />
            <label className="form-check-label" htmlFor="checkToSignature">Time between check-in and signature</label>
          </div>
        </div>
        <div className="row">
          <div className="form-check">
            <input
              type="checkbox"
              id="companyAverage"
              className="form-check-input"
              checked={settings.showCompanyAverage}
              onChange={(e) => updateSettings({ showCompanyAverage: e.target.checked })}
            />
            <label className="form-check-label" htmlFor="companyAverage">Company average</label>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <h5>Sites:</h5>
        <KaSelect
          placeholder="Select Sites"
          options={siteOptions}
          isClearable={true}
          closeMenuOnSelect={false}
          isMulti={true}
          isOptionDisabled={() => settings.selectedSites.length >= 10}
          onChange={(valueObject) => {
            if (isMultiValue(valueObject)) { updateSettings({ selectedSites: valueObject?.map(p => p.value) }) }
          }}
          value={siteOptions.filter((option) => settings.selectedSites.includes(option.value))} />
      </div>
    </>)}
    footer={(
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => saveSettings()}>
        Save
      </button>)}
  />)
}

const DwellTimeDownload = ({ show, onClose }: { show: boolean, onClose: () => void }) => {
  const user = useUser();
  const business = useSelectedBusiness();
  const [params, setParams] = useState<DwellTimeParams>({ dateRange: 'Quarter', download: true });

  const setDateRange = (dateRange: string) => {
    var startDate: string | undefined = undefined;
    var endDate: string | undefined = undefined;

    if (dateRange === 'Custom') {
      startDate = toMydString(dayjs().subtract(1, 'month'));
      endDate = toMydString(dayjs());
    }

    setParams({ ...params, dateRange, startDate, endDate })
  }

  const handleDownload = async () => {
    const url = formatDwellTimeQuery(business.id, params);
    const headers = await user.fetchHeaders();

    download(url, 'dwellTime.csv', headers);
  };

  return <KaModal
    onHide={onClose}
    show={show}
    title={'Dwell Time Download'}
    body={(<>
      <div className="row mt-3">
        <h5>Date Range:</h5>
        <KaSelect
          options={dateRangeOptions}
          value={dateRangeOptions.find((option) => option.value === params.dateRange!)}
          onChange={(valueObject) => {
            if (!isMultiValue(valueObject)) { setDateRange(valueObject?.value!) }
          }}
        />
      </div>
      {
        params.dateRange === 'Custom' &&
        <DateRange
          startDate={params.startDate!}
          endDate={params.endDate!}
          rangeUpdated={(startDate, endDate) => setParams({ ...params, startDate, endDate })} />
      }
    </>)}
    footer={(
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => handleDownload()}>
        Download
      </button>)}
  />
}

const DwellTimePage = () => {
  const [settings, setSettings] = useLocalStorage<Settings>("dwellTime-AdvancedViewSettings", defaultSettings);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showDownload, setShowDownload] = useState<boolean>(false);
  const getSites = useGetSitesNonAdmin();

  const getDwellTime = useGetDwellTime({
    dateRange: settings.dateRange,
    startDate: settings.startDate,
    endDate: settings.endDate,
  });

  const chartHeight = window.screen.height - 310;

  const selectedSiteTimes = (data: DwellTimeDto) => {
    const siteTimes = data.siteTimes
      .filter(st => settings.selectedSites.includes(st.siteId))
      .sort((a, b) => a.siteId.localeCompare(b.siteId));

    if (settings.selectedSites.includes('AllSites')) {
      siteTimes.push({
        siteId: 'AllSites',
        averageLoadTime: data.companyAverageLoadTime,
        averageCheckinTime: data.companyAverageFromCheckinTime,
      });
    }

    return siteTimes;
  }

  return <MultiQueryView
    queries={{ dwellTime: getDwellTime, sites: getSites }}
    renderData={({ dwellTime, sites }) => <>
      <div className="ms-n2 mt-n4 pb-3 me-5 row justify-content-between">
        <DwellTimeSettings
          show={showSettings}
          onClose={() => setShowSettings(false)}
          sites={sites}
          settings={settings}
          saveSettings={setSettings} />
        <DwellTimeDownload
          show={showDownload}
          onClose={() => setShowDownload(false)} />
        <NavLink className="card-link text-nowrap col-auto mb-auto" to="/" style={{ fontSize: 20 }}>
          <i className="bi-chevron-left" />
          Dwell Time
        </NavLink>
        <div className="col-auto">
          <button className="btn col py-0 px-3" onClick={() => setShowDownload(true)} data-testid="download">
            <i className="bi bi-cloud-download ka-blue" style={{ fontSize: "2em" }} />
          </button>
          <button className="btn col py-0 px-3" onClick={() => setShowSettings(true)} data-testid="settings">
            <i className="bi bi-gear ka-blue" style={{ fontSize: "2em" }} />
          </button>
        </div>
      </div>
      <div className="w-100" style={{ height: chartHeight }}>
        <div className="h-100 d-flex justify-content-center">
          <DwellTimeGraph
            {...settings}
            dwellTimes={selectedSiteTimes(dwellTime)}
            sites={sites}
            companyAverageLoadTime={dwellTime.companyAverageLoadTime} />
        </div>
      </div>
    </>
    }
  />
};

export { DwellTimePage };
